import React, { useEffect } from "react";
import Layout from "../Layout";
import "./style.scss";
import { Link } from "gatsby";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import RequestPortfolio from "../RequestPortfolio";
import notesBottom from "../../images/music-notes-bottom.png";
import Button from "../ButtonComponent";

import SEO from "../seo";
import icon1 from "../../images/icons2/icon-01.png";
import icon2 from "../../images/icons2/icon-02.png";
import icon3 from "../../images/icons2/icon-03.png";
import icon4 from "../../images/icons2/icon-04.png";
import icon5 from "../../images/icons2/icon-05.png";
import icon6 from "../../images/icons2/icon-06.png";
import icon7 from "../../images/icons2/icon-07.png";
import lp from "../../images/link preview-02.jpg";

import note from "../../images/musical_note.png";

const services1 = [
  "Audio- Visual Recordings",
  "IVRS Platform Design, Recording and Deployment ",
  "Sound Mixing & Mastering ",
  "Music Composition and Production",
  "Dubbing for TVCs, Documentaries, Features ",
  "Film Scores and Sound Effects",
  "Voice Overs for Training Platforms, E-Learning and E-Books",
  "Narrative Recordings for Programmes",
];

const services2 = [
  "Radio Jingles & Spots",
  "Tabletop Product Photography",
  "Photo Editing and Rendering ",
  "Script Writing ",
  "Video Storyboards ",
  "Videography",
  "Animation",
];

const equipments = [
  {
    img: icon1,
    name: "Microphones",
    devices: [
      "Neumann (TLM-103)",
      "Lewitt (Authentica LCT-240)",
      "Shure SM 57",
    ],
  },
  {
    img: icon2,
    name: "Headphones",
    devices: ["Sennheiser HD180", "Audio Technica- ATM- M10"],
  },
  {
    img: icon3,
    name: "Taking a Stand",
    devices: ["GS Mic Stand", "Neumann Mic Stand", "Script Stand"],
  },
  {
    img: icon4,
    name: "Sound Mixer",
    devices: ["Yamaha O1V 96i"],
  },
  {
    img: icon5,
    name: "Pre-Amplify",
    devices: ["ART Pro Channel II"],
  },
  {
    img: icon6,
    name: "Headphones Amplifier",
    devices: ["ART HeadAmp 4"],
  },
  {
    img: icon7,
    name: "Players in the House",
    devices: ["Sony DVD Player", "Bose DVD Player", "Sonilex Player"],
  },
];

export default function StudioPage() {
  useEffect(() => {
    gsap.from(".textanim", {
      // scale: 5,
      x: -200,
      delay: 0.4,
      duration: 1,
      skewX: 20,
      stagger: 0.3,
      opacity: 0,
      ease: "sine.out",
    });

    //   let proxy = { skew: 0 },
    //     skewSetter = gsap.quickSetter(".skewElem", "skewY", "deg"), // fast
    //     clamp = gsap.utils.clamp(-40, 40); // don't let the skew go beyond 20 degrees.

    //   ScrollTrigger.create({
    //     onUpdate: (self) => {
    //       let skew = clamp(self.getVelocity() / -1000);
    //       // only do something if the skew is MORE severe. Remember, we're always tweening back to 0, so if the user slows their scrolling quickly, it's more natural to just let the tween handle that smoothly rather than jumping to the smaller skew.
    //       if (Math.abs(skew) > Math.abs(proxy.skew)) {
    //         proxy.skew = skew;
    //         gsap.to(proxy, {
    //           skew: 0,
    //           duration: 1,
    //           ease: "power3",
    //           overwrite: true,
    //           onUpdate: () => skewSetter(proxy.skew),
    //         });
    //       }
    //     },
  });

  //   // make the right edge "stick" to the scroll bar. force3D: true improves performance
  //   gsap.set(".skewElem", { transformOrigin: "right center", force3D: true });
  // }, []);

  return (
    <Layout isStudio>
      <SEO
        title="Studios | Book Studio Hours"
        description="Fully equipped, state-of-the-art sound recording studio in Gurgaon, with experienced sound engineers and excellent service. Book studios hours via online booking form.	"
        img="https://blackelephantdigital.com/assets/linkpreviews/studio.jpg"
      />
      <div className="studio__hero">
        <div className="studio__hero__video">
          <div className="studio__hero__video__overlay"></div>
          <video
            autoPlay
            loop
            muted
            className="desktop"
            src="https://blackelephantdigital.com/assets/video/BE%20Montage.mp4"
          ></video>
          <video
            autoPlay
            loop
            muted
            className="mobile"
            src="https://blackelephantdigital.com/assets/video/studio-600x800.mp4"
          ></video>
        </div>
        <img className="studio__hero__img" src={note}></img>

        <div className="studio__hero__text">
          <div className="studio__hero__text__1 textanim ">
            State-of-the-art
          </div>

          <div className="textanim"> Audio-Visual </div>
          {/* <div className="textanim">recording</div> */}
          <div className="textanim" style={{ marginBottom: "20px" }}>
            studio
          </div>

          <span className="studio__hero__btns textanim">
            {/* <Link className="btn-light">book studio slot</Link> */}

            <Button
              className="btn-dark"
              text="book studio slot"
              fillColor="#7AB4B7"
              url="/studio-booking/"
              isATag
            />

            <Button
              className="btn-dark"
              text="reach out"
              // fillColor="#000"
              url="https://api.whatsapp.com/send/?phone=8130047247&text&app_absent=0"
              targetBlank
              isATag
            />
          </span>
        </div>

        <img className="studio__hero__bottomnotes" src={notesBottom}></img>
      </div>

      <div className="studio__services">
        <h2 className="studio__services__heading skewElem">Services</h2>
        <div className="row">
          <div className="col-12 col-md-6 skewElem">
            {services1.map((item) => (
              <div className="studio__services__item ">{item}</div>
            ))}
          </div>
          <div className="col-12 col-md-6 skewElem">
            {services2.map((item) => (
              <div className="studio__services__item ">{item}</div>
            ))}
          </div>
        </div>

        <div className="studio__services__btns">
          <Button
            className="btn-dark"
            text="explore our work"
            fillColor="#A45D5D"
          />
          <Button
            className="btn-dark"
            text="view VO directory"
            fillColor="#F8F0DF"
            url="/vo-directory/"
            isLink
          />
        </div>
      </div>

      <div className="studio__equipments">
        <div className="studio__equipments__heading">
          Studio Recording Equipment
        </div>
        <div className="studio__equipments__text">
          Black Elephant Digital is powered by state-of-the-art technology to
          bring creative vision, business objectives and art(iste) dreams alive,
          in full HD sound and resolution!
        </div>

        <div className="row studio__equipment__row">
          {equipments.map((item) => (
            <div className="col col-6 col-md-4 col-lg-3">
              <div className="studio__equipments__card skewElem">
                <img
                  className="studio__equipments__card__img"
                  src={item.img}
                ></img>
                <div className="studio__equipments__card__name">
                  {item.name}
                </div>
                {item.devices.map((device) => (
                  <div className="studio__equipments__card__device">
                    {device}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>

        <div className="studio__equipments__btns">
          <Button
            className="btn-light"
            text="connect on whatsapp"
            fillColor="#BFA2DB"
            url="https://api.whatsapp.com/send/?phone=8130047247&text&app_absent=0"
            targetBlank
            isATag
          />

          <Button
            className="btn-light"
            text="book studio slot"
            fillColor="#9E7777"
            url="/studio-booking/"
            isLink
          />
        </div>
      </div>
    </Layout>
  );
}
